import { Box, FormLabel } from "@mui/material";
import { format } from "date-fns";
import { ShowInfo } from "../../shared/models/ShowInfo";
import { showsInfoChoices } from "../../shared/enums";
import { useEffect, useState } from "react";

import styles from "./moreshowinfo.module.scss";

type MoreShowInfoProps = {
  screenHeight: number;
  screenWidth: number;
  selectedShow: ShowInfo;
  selectedTab: number;
};

const MoreShowInfo = ({
  screenHeight,
  screenWidth,
  selectedShow,
  selectedTab,
}: MoreShowInfoProps) => {
  const [lessonsLearned, setLessonsLearned] = useState([] as string[]);
  const [scriptureReferences, setScriptureReferences] = useState(
    [] as string[]
  );

  useEffect(() => {
    var lessons = selectedShow.LessonsLearned.split("</>");
    setLessonsLearned(lessons);

    var scriptures = selectedShow.ScriptureReferences.split("</>");
    setScriptureReferences(scriptures);
  }, [selectedShow]);

  return (
    <Box
      className={styles.moreInfoContainer}
      sx={{ height: `calc(${screenHeight}px - 250px)` }}
    >
      {selectedTab === showsInfoChoices.DESCRIPTION && (
        <Box className={styles.moreInfo} sx={{ margin: "0 20px 20px 20px" }}>
          <Box display="flex" flexDirection="row" justifyContent="center">
            {selectedShow.ShowCharacters.map((showCharacter, index) => (
              <img
                key={index}
                style={{ marginRight: "20px", height: "165px" }}
                src={`https://CornbreadKids.com/Images/${showCharacter.Character.ProfilePic}`}
                alt={showCharacter.Character.Name}
              />
            ))}
          </Box>

          <FormLabel sx={{ fontSize: "18px", marginTop: "21px" }}>
            {
              /*selectedShow.Description*/
              selectedShow.Description?.split("\n").map(function (item, idx) {
                return (
                  <span key={idx}>
                    {item}
                    <br />
                  </span>
                );
              })
            }
          </FormLabel>
          <Box display="flex" flexDirection="column" sx={{ marginTop: "21px" }}>
            <FormLabel sx={{ fontSize: "18px", fontWeight: "bold" }}>
              Memory Verse:
            </FormLabel>
            <FormLabel sx={{ fontSize: "18px" }}>
              {selectedShow.MemoryVerse}
            </FormLabel>
          </Box>
          {selectedShow.PublishDate && (
            <FormLabel sx={{ fontSize: "18px", margin: "21px 0 7px 0" }}>
              {`published: ${format(
                new Date(selectedShow.PublishDate),
                "MM/dd/yyyy"
              )}`}
            </FormLabel>
          )}
        </Box>
      )}
      {selectedTab === showsInfoChoices.LESSONS && (
        <Box className={styles.moreInfo} sx={{ margin: "10px 20px 20px 20px" }}>
          {lessonsLearned.map((lesson, index) => (
            <FormLabel
              key={index}
              sx={{ fontSize: "18px", marginBottom: "21px" }}
            >
              {lesson}
            </FormLabel>
          ))}
        </Box>
      )}
      {selectedTab === showsInfoChoices.SCRIPTURES && (
        <Box className={styles.moreInfo} sx={{ margin: "10px 20px 20px 20px" }}>
          {scriptureReferences.map((scripture, index) => (
            <FormLabel
              key={index}
              sx={{ fontSize: "18px", marginBottom: "21px" }}
            >
              {scripture}
            </FormLabel>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default MoreShowInfo;
