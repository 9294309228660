import {
  Box,
  Card,
  CardActions,
  CardContent,
  Dialog,
  FormLabel,
  Link,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { CharacterInfo } from "../../shared/models/CharacterInfo";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import { filterChoices } from "../../shared/enums";
import { format } from "date-fns";
import GA4React from "ga-4-react";
import { KeywordInfo } from "../../shared/models/KeywordInfo";
import MenuIcon from "@mui/icons-material/Menu";
import MoreShowInfo from "./MoreShowInfo";
import SearchHighlightIcon from "../../Images/SearchHighlighted-min.png";
import SearchIcon from "../../Images/Search-min.png";
import { ShowInfo } from "../../shared/models/ShowInfo";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../shared/windowDimensions";
import YouTubePlayButton from "../../Images/YouTubePlayButton-min.png";

import styles from "./shows.module.scss";

type ShowsProps = {
  ga4react: GA4React;
  isMobileDevice: boolean;
};

const Shows = ({ ga4react, isMobileDevice }: ShowsProps) => {
  const [announcement, setAnnouncement] = useState("");
  const [characterList, setCharacterList] = useState([] as CharacterInfo[]);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isMoreInfoShowing, setIsMoreInfoShowing] = useState(false);
  const [pageContentHeight, setPageContentHeight] = useState("");
  const [keywordsList, setKeywordsList] = useState([] as KeywordInfo[]);
  const { screenHeight, screenWidth } = useWindowDimensions();
  const [selectedCharacter, setSelectedCharacter] = useState(
    {} as CharacterInfo
  );
  const [selectedFilterChoice, setSelectedFilterChoice] = useState(
    filterChoices.CHARACTER
  );
  const [selectedKeyword, setSelectedKeyword] = useState({} as KeywordInfo);
  const [selectedShow, setSelectedShow] = useState({} as ShowInfo);
  const [selectedTab, setSelectedTab] = useState(0);
  const [showsList, setShowsList] = useState([] as ShowInfo[]);

  useEffect(() => {
    getAnnouncement();
  }, []);

  useEffect(() => {
    if (!isFiltering) return;

    if (!characterList.length) {
      getCharacters();
    }
    if (!keywordsList.length) {
      getKeywords();
    }
  }, [characterList, isFiltering, keywordsList]);

  useEffect(() => {
    if (!isFiltering) {
      getShows();
    } else if (selectedFilterChoice === filterChoices.CHARACTER) {
      getShowsForCharacter();
    } else {
      getShowsForKeyword();
    }
  }, [isFiltering, selectedCharacter, selectedFilterChoice, selectedKeyword]);

  useEffect(() => {
    setPageContentHeight(
      isMobileDevice
        ? `calc(${screenHeight}px - 200px)`
        : `calc(${screenHeight}px - 355px)`
    );
  }, [isMobileDevice, screenHeight]);

  const getAnnouncement = async () => {
    var response = await fetch(
      "https://cornbreadkids.com/CornbreadKids/api/Message"
    );
    var announcement = await response.json();
    setAnnouncement(announcement);
  };

  const getCharacters = async () => {
    try {
      const response = await fetch(
        "https://cornbreadkids.com/CornbreadKids/api/Characters"
      );
      var characters = (await response.json()) as CharacterInfo[];
      if (characters?.length) {
        var msAprilIndex = characters.findIndex(
          (characterToFind) => characterToFind.Name === "Ms. April"
        );
        characters.splice(msAprilIndex, 1);
        var msConiIndex = characters.findIndex(
          (characterToFind) => characterToFind.Name === "Ms. Coni"
        );
        characters.splice(msConiIndex, 1);
        setSelectedCharacter(characters[0]);
      }
      setCharacterList(characters);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getKeywords = async () => {
    // Now get the list of keywords from the database...
    var response = await fetch(
      "https://cornbreadkids.com/CornbreadKids/api/Keywords"
    );
    var keywords = await response.json();
    if (keywords?.length) {
      setSelectedKeyword(keywords[0]);
    }
    setKeywordsList(keywords);
  };

  const getShows = async () => {
    try {
      // Get the list of shows via the json file
      var response = await fetch(
        "https://cornbreadkids.com/CornbreadKids/api/Shows/ShowsList"
      );
      var shows = await response.json();
      setShowsList(shows);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getShowsForCharacter = async () => {
    if (!selectedCharacter?.Id) return;
    try {
      // Get the list of shows via the json file
      var response = await fetch(
        `https://cornbreadkids.com/CornbreadKids/api/Shows/ShowsForCharacter/${selectedCharacter.Id}`
      );
      var shows = await response.json();
      setShowsList(shows);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getShowsForKeyword = async () => {
    if (!selectedKeyword?.Id) return;
    try {
      // Get the list of shows via the json file
      var response = await fetch(
        `https://cornbreadkids.com/CornbreadKids/api/Shows/ShowsForKeyword/${selectedKeyword.Id}`
      );
      var shows = await response.json();
      setShowsList(shows);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const moreInfoButtonClicked = async (showId: number) => {
    try {
      // Look up the info for the selected show...
      var response = await fetch(
        `https://cornbreadkids.com/CornbreadKids/api/Shows/${showId}`
      );
      var showInfo = (await response.json()) as ShowInfo;

      setSelectedShow(showInfo);
      setSelectedTab(0);
      setIsMoreInfoShowing(true);

      ga4react.event(
        `More Info clicked: ${showInfo.Title}`,
        showInfo.Title,
        "Click",
        false
      );
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const onCharacterChoiceChanged = (value: any) => {
    var character = characterList.find(
      (characterToFind) => characterToFind.Id === value
    );
    if (character) {
      setSelectedCharacter(character);
    }
  };

  const onFilterChoiceChanged = (value: any) => {
    setSelectedFilterChoice(value);
  };

  const onKeywordChoiceChanged = (value: any) => {
    var keyword = keywordsList.find(
      (keywordToFind) => keywordToFind.Id === value
    );
    if (keyword) {
      setSelectedKeyword(keyword);
    }
  };

  return (
    <Box
      className={styles.pageContent}
      style={{
        height: pageContentHeight,
        justifyContent: "center",
        marginTop: "10px",
      }}
    >
      <Box className={styles.filterContainer}>
        <FormLabel
          className={styles.description}
          sx={{
            color: "black",
            fontSize: "20px",
            margin: isMobileDevice ? "10px 30px 0 30px" : "10px 60px 0 60px",
            width: "98%",
          }}
        >
          Join Billy, Beulah and their friends as they learn Bible stories!
        </FormLabel>
        {!isFiltering && (
          <Tooltip
            title={
              <Typography fontSize="14px">
                Filter the Shows by Character or Topic
              </Typography>
            }
            sx={{ fontSize: "14px" }}
          >
            <img
              className={clsx(
                isMobileDevice ? styles.searchIconMobile : styles.searchIcon
              )}
              src={SearchIcon}
              alt="Search"
              onClick={() => setIsFiltering(!isFiltering)}
              onMouseOver={(e) => (e.currentTarget.src = SearchHighlightIcon)}
              onMouseLeave={(e) => (e.currentTarget.src = SearchIcon)}
            />
          </Tooltip>
        )}
      </Box>
      {isFiltering && (
        <Box className={styles.filterContainer} sx={{ height: "40px" }}>
          <Select
            className={
              isMobileDevice
                ? styles.filterChoiceListMobile
                : styles.filterChoiceList
            }
            style={{ fontSize: isMobileDevice ? "16px" : "20px" }}
            value={selectedFilterChoice}
            onChange={(event) => onFilterChoiceChanged(event.target.value)}
          >
            <MenuItem value={filterChoices.CHARACTER}>Character</MenuItem>
            <MenuItem value={filterChoices.KEYWORD}>Keyword</MenuItem>
          </Select>
          {selectedFilterChoice === filterChoices.CHARACTER &&
            characterList.length > 0 && (
              <Select
                className={clsx(
                  isMobileDevice
                    ? styles.characterChoiceListMobile
                    : styles.characterChoiceList
                )}
                style={{ fontSize: isMobileDevice ? "16px" : "20px" }}
                value={selectedCharacter.Id}
                onChange={(event) =>
                  onCharacterChoiceChanged(event.target.value)
                }
              >
                {characterList?.map((character) => (
                  <MenuItem key={character.Id} value={character.Id}>
                    {character.Name}
                  </MenuItem>
                ))}
              </Select>
            )}
          {selectedFilterChoice === filterChoices.KEYWORD &&
            keywordsList.length > 0 && (
              <Select
                className={clsx(
                  isMobileDevice
                    ? styles.keywordChoiceListMobile
                    : styles.keywordChoiceList
                )}
                style={{ fontSize: isMobileDevice ? "16px" : "20px" }}
                value={selectedKeyword.Id}
                onChange={(event) => onKeywordChoiceChanged(event.target.value)}
              >
                {keywordsList?.map((keyword) => (
                  <MenuItem key={keyword.Id} value={keyword.Id}>
                    {keyword.Tag}
                  </MenuItem>
                ))}
              </Select>
            )}
          <Tooltip
            title={
              <Typography fontSize="14px">
                Filter the Shows by Character or Topic
              </Typography>
            }
            sx={{ fontSize: "14px" }}
          >
            {/* style={{marginTop: isFiltering ? "0" : "-28px"}} */}
            <img
              className={clsx(
                isMobileDevice ? styles.searchIconMobile : styles.searchIcon
              )}
              src={SearchIcon}
              alt="Search"
              onClick={() => setIsFiltering(!isFiltering)}
              onMouseOver={(e) => (e.currentTarget.src = SearchHighlightIcon)}
              onMouseLeave={(e) => (e.currentTarget.src = SearchIcon)}
            />
          </Tooltip>
        </Box>
      )}

      {!isFiltering && announcement?.length > 0 && (
        <FormLabel
          className={styles.description}
          sx={{
            color: "#1976D2",
            fontSize: "20px",
            fontWeight: "bold",
            margin: isMobileDevice ? "15px 25px 0 25px" : "15px 60px 0 60px",
            width: "98%",
          }}
        >
          {announcement}
        </FormLabel>
      )}

      {showsList.length === 0 && (
        <FormLabel className={styles.noShowsMessage} sx={{ fontSize: "18px" }}>
          No shows matching the selected filter
        </FormLabel>
      )}

      <Box
        className={styles.pageContent}
        style={{
          justifyContent: "center",
          marginTop: "10px",
          minHeight: "280px",
        }}
      >
        {showsList.map((show) => (
          <Card
            key={show.Id}
            className={styles.cards}
            sx={{
              border: "1px solid black",
              borderRadius: "12px",
              height: "300px",
              margin: "15px",
            }}
          >
            <CardContent>
              <Box className={styles.cardTitle}>
                <FormLabel
                  sx={{ color: "black", fontSize: "18px", fontWeight: "bold" }}
                >
                  {show.Title}
                </FormLabel>
                <Tooltip
                  title={
                    <Typography fontSize="14px">Display More Info</Typography>
                  }
                  sx={{ fontSize: "14px" }}
                >
                  <MenuIcon
                    className={styles.menuIcon}
                    sx={{
                      height: isMobileDevice ? "45px" : "25px",
                      width: isMobileDevice ? "50px" : "30px",
                    }}
                    onClick={() => moreInfoButtonClicked(show.Id)}
                  />
                </Tooltip>
              </Box>
              {/* TODO: Figure out why the top margin isn't working */}
              {/* <FormLabel sx={{color: 'black', fontSize: '15px', marginTop: '-7px'}}>{show.SubTitle}</FormLabel> */}
            </CardContent>
            <CardActions className={styles.cardActions}>
              <Link
                href={`https://youtu.be/${show.YouTubeId}&mute=1`}
                target="_blank"
                rel="noreferrer"
                underline="none"
                onClick={() =>
                  ga4react.event(
                    `Video Clicked: ${show.Title}`,
                    show.Title,
                    "Click",
                    false
                  )
                }
              >
                <Box className={styles.cardActionContainer}>
                  <img
                    src={`https://img.youtube.com/vi/${show.YouTubeId}/mqdefault.jpg`}
                    alt={show.Title}
                  />
                  <FormLabel sx={{ marginTop: "10px" }}>
                    {`published: ${format(
                      new Date(show.PublishDate),
                      "MM/dd/yyyy"
                    )}`}
                  </FormLabel>
                  <img
                    style={{
                      marginLeft: "140px",
                      marginTop: "-140px",
                      width: "44px",
                      zIndex: "7",
                    }}
                    src={YouTubePlayButton}
                    alt="Play video on YouTube"
                  />
                </Box>
              </Link>
            </CardActions>
          </Card>
        ))}
        <Dialog
          open={isMoreInfoShowing}
          onClose={() => setIsMoreInfoShowing(false)}
        >
          <Box className={styles.stickyHeader}>
            <Box className={styles.moreInfoHeader}>
              <FormLabel
                sx={{
                  color: "black",
                  fontSize: "20px",
                  fontWeight: "bold",
                  margin: "30px 20px 10px 20px",
                }}
              >
                {`${selectedShow.Title} - ${selectedShow.SubTitle}`}
              </FormLabel>
              <CloseIcon
                className={styles.closeButton}
                sx={{
                  cusrsor: "pointer",
                  height: "35px",
                  margin: "10px",
                  width: "35px",
                }}
                onClick={() => setIsMoreInfoShowing(false)}
              />
            </Box>
            <Tabs
              className={styles.tabs}
              value={selectedTab}
              centered
              onChange={(event, value) => setSelectedTab(value)}
              sx={{
                position: "sticky",
                top: "0",
                "& .MuiTab-root": {
                  fontSize: "18px;",
                  fontWeight: "600",
                  padding: isMobileDevice ? "12px 7px" : "12px 16px",
                  textTransform: "none",
                },
              }}
            >
              <Tab label="Description" />
              <Tab label="Lessons" />
              <Tab label="Scriptures" />
            </Tabs>
          </Box>

          <MoreShowInfo
            screenHeight={screenHeight}
            screenWidth={screenWidth}
            selectedShow={selectedShow}
            selectedTab={selectedTab}
          />
        </Dialog>
      </Box>
    </Box>
  );
};

export default Shows;
