import { Box, FormLabel, Link, Menu, MenuItem, Tab, Tabs } from "@mui/material";
import GA4React from "ga-4-react";
import { getTabName } from "../../shared/SharedFunctions";
import { menuChoices } from "../../shared/enums";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
//import youTubeShare from "../../Images/YouTubeShare-min.png";
import youTubeSubscribe from "../../Images/YouTubeSubscribe-min.png";

import styles from "./pageSelections.module.scss";

type PageSelectionsProps = {
  ga4react: GA4React;
  isMobileDevice: boolean;
  selectedTab: number;
  setSelectedTab: Function;
};

const PageSelections = ({
  ga4react,
  isMobileDevice,
  selectedTab,
  setSelectedTab,
}: PageSelectionsProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const isMobileMenuDisplayed = Boolean(menuAnchorEl !== null);

  const mobileMenuSelected = (selectedMenu: number) => {
    setSelectedTab(selectedMenu);
    setMenuAnchorEl(null);
  };

  const onDisplaySettingsMenu = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  return (
    <Box>
      {isMobileDevice && (
        <>
          <Box className={styles.mobileMenuBar}>
            <MenuIcon
              className={styles.menuIcon}
              onClick={onDisplaySettingsMenu}
              sx={{ height: "45px", margin: "2px 4px 2px 4px", width: "50px" }}
            />
            <FormLabel
              className={styles.mobileMenuBarText}
              sx={{
                color: "inherit",
                fontSize:
                  //selectedTab === menuChoices.BIBLEBEDTIMESTORIES
                  //</Box>  ? "17px"
                  //:
                  "20px",
                fontWeight: "500",
              }}
            >
              {getTabName(selectedTab)}
            </FormLabel>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              margin="3px 9px 0 auto"
            >
              <Link
                href={
                  "https://www.youtube.com/@cornbreadkids/?sub_confirmation=1"
                }
                onClick={() =>
                  ga4react.event(
                    "Subscribe Button Clicked",
                    "Subscribe",
                    "Click",
                    false
                  )
                }
                target="_blank"
                rel="noreferrer"
                underline="none"
              >
                <img
                  style={{ height: "32px", marginLeft: "1px" }}
                  src={youTubeSubscribe}
                  alt="Subcribe to the Cornbread Kids YouTube channel"
                />
              </Link>
              {/* <img style={{height:'48px', margin:'0 0 0 10px'}} src={youTubeShare} alt="Share the Cornbread Kids YouTube channel" /> */}
            </Box>
          </Box>

          <Menu
            anchorEl={menuAnchorEl}
            open={isMobileMenuDisplayed}
            onClose={() => setMenuAnchorEl(null)}
          >
            <MenuItem
              sx={{ fontSize: "20px" }}
              onClick={() => mobileMenuSelected(menuChoices.SHOWS)}
            >
              SHOWS
            </MenuItem>
            <MenuItem
              sx={{ fontSize: "20px" }}
              onClick={() => mobileMenuSelected(menuChoices.TEAM)}
            >
              TEAM
            </MenuItem>
            <MenuItem
              sx={{ fontSize: "20px" }}
              onClick={() => mobileMenuSelected(menuChoices.ABOUT)}
            >
              ABOUT US
            </MenuItem>
            <MenuItem
              sx={{ fontSize: "20px" }}
              onClick={() => mobileMenuSelected(menuChoices.BOOKS)}
            >
              BOOKS
            </MenuItem>
            <MenuItem
              sx={{ fontSize: "20px" }}
              onClick={() =>
                mobileMenuSelected(menuChoices.BIBLEBEDTIMESTORIES)
              }
            >
              BIBLE STORIES
            </MenuItem>
            {/* <MenuItem
              sx={{ fontSize: "20px" }}
              onClick={() => mobileMenuSelected(menuChoices.BLOGS)}
            >
              BLOGS
            </MenuItem> */}
          </Menu>
        </>
      )}

      {!isMobileDevice && (
        //
        <Box display="flex" flexDirection="row" justifyContent="center">
          <Tabs
            centered
            className={styles.tabs}
            onChange={(event, value) => {
              if (value !== selectedTab) {
                setSelectedTab(value);
              }
            }}
            sx={{
              "& .MuiTab-root": {
                fontSize: "20px;",
                fontWeight: "600",
                justifyContent: "space-evenly",
                minWidth: "70px",
                padding: "12px 12px",
                textTransform: "none",
              },
            }}
            TabIndicatorProps={{
              style: {
                marginLeft: `${
                  selectedTab === menuChoices.SHOWS
                    ? "10px"
                    : selectedTab === menuChoices.TEAM
                    ? "10px"
                    : selectedTab === menuChoices.ABOUT
                    ? "8px"
                    : menuChoices.BOOKS
                    ? "10px"
                    : "10px"
                }`,
                width: `${
                  selectedTab === menuChoices.SHOWS
                    ? "72px"
                    : selectedTab === menuChoices.TEAM
                    ? "58px"
                    : selectedTab === menuChoices.ABOUT
                    ? "100px"
                    : selectedTab === menuChoices.BOOKS
                    ? "66px"
                    : selectedTab === menuChoices.BIBLEBEDTIMESTORIES
                    ? "135px"
                    : "60px"
                }`,
                top: "36px",
              },
            }}
            value={selectedTab}
          >
            <Tab label="Shows" />
            <Tab label="Team" />
            <Tab label="About Us" />
            <Tab label="Books" />
            <Tab label="Bible Stories" />
            {/* <Tab label="Blogs" /> */}
          </Tabs>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            marginTop="20px"
          >
            <Link
              href={
                "https://www.youtube.com/@cornbreadkids/?sub_confirmation=1"
              }
              target="_blank"
              rel="noreferrer"
              underline="none"
            >
              <img
                style={{ height: "32px", marginLeft: "10px" }}
                src={youTubeSubscribe}
                alt="Subcribe to the Cornbread Kids YouTube channel"
              />
            </Link>
            {/* <img style={{height:'48px', margin:'0 0 0 10px'}} src={youTubeShare} alt="Share the Cornbread Kids YouTube channel" /> */}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PageSelections;
