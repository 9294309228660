import AboutUs from "./components/aboutUs/AboutUs";
import banner from "./Images/Banner-min.jpg";
import BibleBedtimeStories from "./components/bibleBedtimeStories/BibleBedtimeStories";
import Blogs from "./components/blogs/Blogs";
import Books from "./components/books/Books";
import { Box, FormLabel } from "@mui/material";
import GA4React from "ga-4-react";
import { getTabName } from "./shared/SharedFunctions";
import { menuChoices } from "./shared/enums";
import PageSelections from "./components/pageSelections/PageSelections";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Shows from "./components/shows/Shows";
import Team from "./components/team/Team";
import { useEffect, useState } from "react";
import useWindowDimensions from "./shared/windowDimensions";

import styles from "./app.module.scss";

type AppProps = {
  ga4react: GA4React;
};

function App({ ga4react }: AppProps) {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  var isUpdating: boolean = false;
  const location = useLocation();
  const navigate = useNavigate();
  const { screenHeight, screenWidth } = useWindowDimensions();
  const [selectedTab, setSelectedTab] = useState(menuChoices.SHOWS);

  useEffect(() => {
    if (isUpdating) {
      isUpdating = false;
      return;
    }

    isUpdating = true;

    switch (location.pathname.toLowerCase()) {
      case "/":
      case "/shows":
        if (selectedTab !== menuChoices.SHOWS) {
          setSelectedTab(menuChoices.SHOWS);
        }
        break;
      case "/about":
        if (selectedTab !== menuChoices.ABOUT)
          setSelectedTab(menuChoices.ABOUT);
        break;
      case "/books":
        if (selectedTab !== menuChoices.BOOKS)
          setSelectedTab(menuChoices.BOOKS);
        break;
      case "/biblebedtimestories":
        if (selectedTab !== menuChoices.BIBLEBEDTIMESTORIES)
          setSelectedTab(menuChoices.BIBLEBEDTIMESTORIES);
        break;
      case "/blogs":
        if (selectedTab !== menuChoices.BLOGS)
          setSelectedTab(menuChoices.BLOGS);
        break;
      case "/team":
        if (selectedTab !== menuChoices.TEAM) setSelectedTab(menuChoices.TEAM);
        break;
    }
  }, [location]);

  useEffect(() => {
    setIsMobileDevice(screenWidth < 700);
  }, [screenWidth]);

  useEffect(() => {
    if (isUpdating) {
      isUpdating = false;
      return;
    }

    isUpdating = true;

    if (
      selectedTab === menuChoices.SHOWS &&
      location.pathname !== "/" &&
      location.pathname.toLowerCase() !== "/shows"
    ) {
      navigate("/");
    } else if (
      selectedTab === menuChoices.ABOUT &&
      location.pathname !== "/" &&
      location.pathname.toLowerCase() !== "/about"
    ) {
      navigate("/About");
    } else if (
      selectedTab === menuChoices.BOOKS &&
      location.pathname !== "/" &&
      location.pathname.toLowerCase() !== "/books"
    ) {
      navigate("/Books");
    } else if (
      selectedTab === menuChoices.BIBLEBEDTIMESTORIES &&
      location.pathname !== "/" &&
      location.pathname.toLocaleLowerCase() !== "/biblebedtimestories"
    ) {
      navigate("/BibleBedtimeStories");
    } else if (
      selectedTab === menuChoices.BLOGS &&
      location.pathname !== "/" &&
      location.pathname.toLowerCase() !== "/blogs"
    ) {
      navigate("/Blogs");
    } else if (
      selectedTab === menuChoices.TEAM &&
      location.pathname !== "/" &&
      location.pathname.toLowerCase() !== "/team"
    ) {
      navigate("/Team");
    }

    ga4react.event(
      `Tab clicked : ${getTabName(selectedTab)}`,
      getTabName(selectedTab) as string,
      "Click",
      false
    );
  }, [selectedTab]);

  return (
    <Box className={styles.app}>
      {isMobileDevice && (
        <Box
          sx={{
            borderBottom: "1px solid #A8A8B7",
            minWidth: "400px",
            overflow: "hidden",
            height: "120px",
          }}
        >
          <img
            style={{
              display: "block",
              height: "100%",
              margin: "0 auto",
              objectFit: "cover",
              transform: "scale(1.5",
              width: "100%",
            }}
            src={banner}
            alt="Cornbread Kids Bible Story Puppet Shows for Kids"
          />
        </Box>
      )}
      {!isMobileDevice && (
        <Box
          sx={{
            borderBottom: "1px solid #A8A8B7",
            minWidth: "400px",
            overflow: "hidden",
            height: "250px",
          }}
        >
          <img
            style={{
              display: "block",
              height: "100%",
              margin: "0 auto",
              maxWidth: "1400px",
              objectFit: "cover",
              transform: "scale(1.1",
              width: "100%",
            }}
            src={banner}
            alt="Cornbread Kids Bible Story Puppet Shows for Kids"
          />
        </Box>
      )}

      <PageSelections
        ga4react={ga4react}
        isMobileDevice={isMobileDevice}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />

      <Box className={styles.pageContent}>
        <Routes>
          <Route
            path="/"
            element={
              selectedTab === menuChoices.SHOWS ? (
                <Shows ga4react={ga4react} isMobileDevice={isMobileDevice} />
              ) : selectedTab === menuChoices.TEAM ? (
                <Team isMobileDevice={isMobileDevice} />
              ) : selectedTab === menuChoices.ABOUT ? (
                <AboutUs isMobileDevice={isMobileDevice} />
              ) : selectedTab === menuChoices.BOOKS ? (
                <Books isMobileDevice={isMobileDevice} />
              ) : selectedTab === menuChoices.BIBLEBEDTIMESTORIES ? (
                <BibleBedtimeStories
                  ga4react={ga4react}
                  isMobileDevice={isMobileDevice}
                />
              ) : (
                <Blogs isMobileDevice={isMobileDevice} />
              )
            }
          />
          <Route
            path="/Shows"
            element={
              <Shows ga4react={ga4react} isMobileDevice={isMobileDevice} />
            }
          />
          <Route
            path="/Team"
            element={<Team isMobileDevice={isMobileDevice} />}
          />
          <Route
            path="/About"
            element={<AboutUs isMobileDevice={isMobileDevice} />}
          />
          <Route
            path="/Books"
            element={<Books isMobileDevice={isMobileDevice} />}
          />
          <Route
            path="/BibleBedtimeStories"
            element={
              <BibleBedtimeStories
                ga4react={ga4react}
                isMobileDevice={isMobileDevice}
              />
            }
          />
          <Route
            path="/Blogs"
            element={<Blogs isMobileDevice={isMobileDevice} />}
          />
          <Route
            path="*"
            element={
              <Shows ga4react={ga4react} isMobileDevice={isMobileDevice} />
            }
          />
        </Routes>
      </Box>

      <Box className={styles.footer}>
        <FormLabel
          sx={{ color: "white", fontSize: "12px", marginBottom: "2px" }}
        >
          Copyright © 2024 Mud Pie Creations. All Rights Reserved.
        </FormLabel>
      </Box>
    </Box>
  );
}

export default App;
