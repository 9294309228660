import { Box } from "@mui/material";
import underConstruction from "../../Images/PageUnderConstruction-min.jpg";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../shared/windowDimensions";

import styles from "./blogs.module.scss";

type BlogsProps = {
  //ga4: any;
  isMobileDevice: boolean;
};

const Blogs = ({ isMobileDevice }: BlogsProps) => {
  const [pageContentHeight, setPageContentHeight] = useState("");
  const { screenHeight, screenWidth } = useWindowDimensions();

  useEffect(() => {
    setPageContentHeight(
      isMobileDevice
        ? `calc(${screenHeight}px - 200px)`
        : `calc(${screenHeight}px - 325px)`
    );
  }, [isMobileDevice, screenHeight]);

  return (
    <Box className={styles.pageContent} style={{ height: pageContentHeight }}>
      <img
        style={{
          display: "block",
          height: "100%",
          margin: "0 auto",
          maxWidth: "1400px",
          objectFit: "contain",
          width: "100%",
        }}
        src={underConstruction}
        alt="Page Under Construction"
      />
    </Box>
  );
};

export default Blogs;
