import React from "react";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { createRoot } from "react-dom/client";
import GA4React from "ga-4-react";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

const ga4react = new GA4React("G-7MHKBB2S7D");

(async (_) => {
  await ga4react
    .initialize()
    //.then(res => console.log("Analytics Success."))
    .catch((err) => console.error("Analytics Failure."))
    .finally(() => {
      root.render(
        <React.StrictMode>
          <Router>
            <App ga4react={ga4react} />
          </Router>
        </React.StrictMode>
      );
    });
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
