import { Box, FormLabel } from "@mui/material";
import { CharacterInfo } from "../../shared/models/CharacterInfo";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../shared/windowDimensions";

import styles from "./team.module.scss";

type TeamProps = {
  //ga4: any
  isMobileDevice: boolean;
};

const Team = ({ isMobileDevice }: TeamProps) => {
  const [characterList, setCharacterList] = useState([] as CharacterInfo[]);
  const [imageContainerWidth, setImageContainerWidth] = useState("");
  const [imageWidth, setImageWidth] = useState("");
  const [pageContentHeight, setPageContentHeight] = useState("");
  const { screenHeight, screenWidth } = useWindowDimensions();

  useEffect(() => {
    getCharacters();
  }, []);

  useEffect(() => {
    setPageContentHeight(
      isMobileDevice
        ? `calc(${screenHeight}px - 230px)`
        : `calc(${screenHeight}px - 355px)`
    );
    setImageContainerWidth(
      isMobileDevice
        ? `calc(${screenWidth}px - 30px)`
        : `calc(${screenWidth}px - 420px)`
    );
    setImageWidth(
      isMobileDevice
        ? `calc(${screenWidth}px  - 50px)`
        : `calc(${screenWidth}px - 440px)`
    );
  }, [isMobileDevice, screenHeight, screenWidth]);

  const getCharacters = async () => {
    try {
      const response = await fetch(
        "https://cornbreadkids.com/CornbreadKids/api/Characters"
      );
      var characters = await response.json();
      setCharacterList(characters);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  return (
    <Box className={styles.pageContent} style={{ height: pageContentHeight }}>
      {characterList.map((character) => (
        <Box key={character.Id} sx={{ padding: "10px" }}>
          <Box
            sx={{
              display: "flex",
              margin:
                character.Name === "Beulah"
                  ? "15px auto 0 auto"
                  : "45px auto 0 auto",
            }}
          >
            <FormLabel
              sx={{
                color: "black",
                fontSize: "28px",
                fontWeight: "bold",
                margin: "auto",
              }}
            >
              {character.FullName}
            </FormLabel>
          </Box>
          <Box
            sx={{
              backgroundColor: "black",
              display: "flex",
              margin: "0 auto 0 auto",
              maxWidth: "800px",
              width: imageContainerWidth,
            }}
          >
            <img
              style={{
                display: "block",
                margin: "10px",
                objectFit: "cover",
                maxWidth: "780px",
                width: imageWidth,
              }}
              src={`https://CornbreadKids.com/Images/${character.ImageName}`}
              alt={character.Name}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              margin: "15px auto 0 auto",
              maxWidth: "800px",
              width: imageContainerWidth,
            }}
          >
            <FormLabel
              sx={{ color: "black", fontSize: "20px", padding: "10px" }}
            >
              {character.Bio}
            </FormLabel>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Team;
