export const filterChoices = {
  CHARACTER: 0,
  KEYWORD: 1,
};

export const menuChoices = {
  SHOWS: 0,
  TEAM: 1,
  ABOUT: 2,
  BOOKS: 3,
  BIBLEBEDTIMESTORIES: 4,
  BLOGS: 5,
};

export const showsInfoChoices = {
  DESCRIPTION: 0,
  LESSONS: 1,
  SCRIPTURES: 2,
};
